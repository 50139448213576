<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Quản lý thanh toán tự động</h6>
              </template>
              <b-form class="col-12">
                <b-row>
                  <b-col>
                    <b-form-group label="Số điện thoại">
                      <b-form-input
                        v-model="formInput.phone"
                        placeholder="Nhập số điện thoại"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Nhà mạng">
                      <b-form-select
                        v-model="formInput.service"
                        :options="options.service"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Loại dịch vụ">
                      <b-form-select
                        v-model="formInput.service_type"
                        :options="options.service_type"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Từ ngày">
                      <b-form-input
                        :id="`type-date`"
                        :type="`date`"
                        v-model="formInput.from"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Đến ngày">
                      <b-form-input
                        :id="`type-date`"
                        :type="`date`"
                        v-model="formInput.to"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row style="float: right">
                  <b-button type="submit" variant="primary" @click="listAll(1)"
                    >Tìm kiếm </b-button
                  >&nbsp;
                  <b-button
                    type="submit"
                    variant="primary"
                    @click="formatInput()"
                    >Đặt lại
                  </b-button>
                </b-row>
                <b-row> </b-row>
              </b-form>
              <b-table
                :items="items"
                :fields="fields"
                ref="table"
                striped
                hover
                responsive
                caption-top
              >
                <template #cell(index)="data">
                  {{ (currentPage - 1) * 15 + (data.index + 1) }}
                </template>
                <template #cell(action)="data">
                  <span v-if="data.item.action === 'TOPUP_MOBILE'"
                    >Nạp tiền điện thoại</span
                  >
                  <span v-else-if="data.item.action === 'TOPUP_DATA'"
                    >Nạp data 3G/4G</span
                  >
                  <span v-else>{{ data.item.action }}</span>
                </template>
                <template #cell(is_active)="data">
                  <b-badge v-if="data.item.is_active === 1" variant="success"
                    >Hoạt động</b-badge
                  >
                  <b-badge v-else variant="warning">Tạm dừng</b-badge>
                </template>
                <template #cell(updated_at)="data">
                  {{ data.item.updated_at | formatDateTime }}
                </template>
                <template #cell(options)="data">
                  <a
                    :href="`#/transactions/auto-payment/detail/${data.item.id}`"
                    variant="primary"
                    >Chi tiết</a
                  >
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.total"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const CmsRepository = RepositoryFactory.get("cms");
export default {
  mixins: [Common],
  data() {
    return {
      formInput: {
        phone: null,
        service: null,
        service_type: null,
        from: this.getYesterday(),
        to: this.getCurrentDay(),
      },
      options: {
        service: [
          { value: null, text: "Tất cả" },
          { value: "VINAPHONE", text: "Vinaphone" },
          { value: "VIETTEL", text: "Viettel" },
          { value: "MOBIFONE", text: "Mobifone" },
        ],
        service_type: [
          { value: null, text: "Tất cả" },
          { value: "TOPUP_MOBILE", text: "Nạp tiền điện thoại" },
          { value: "TOPUP_DATA", text: "Nạp data 3G/4G" },
        ],
      },
      fields: [
        { index: "#" },
        { user_phone: "Số điện thoại" },
        { action: "Loại dịch vụ" },
        { service: "Nhà mạng" },
        { price: "Mệnh giá" },
        { frequent_load: "tần suất nạp" },
        { is_active: "Trạng thái" },
        { updated_at: "Thời gian cập nhật" },
        { options: "Hành động" },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        lastPage: 1,
        total: 1,
        skip: 0,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý thanh toán tự động", route: "list" },
    ]);
    this.filter = this.$route.query;
  },
  methods: {
    formatInput() {
      this.formInput = {
        phone: null,
        service: null,
        service_type: null,
        from: this.getYesterday(),
        to: this.getCurrentDay(),
      };
      this.listAll();
    },
    listAll(numberPage = null) {
      if (this.$route.query.page !== "undefined" && numberPage == null) {
        numberPage = this.$route.query.page;
      } else {
        this.currentPage = 1;
      }
      this.$route.query.page = numberPage;
      let params = {
        page: numberPage,
      };
      if (this.formInput.phone != null && this.formInput.phone != "") {
        params.user_phone = this.formInput.phone;
      }
      if (this.formInput.service != null && this.formInput.service != "") {
        params.service = this.formInput.service;
      }
      if (
        this.formInput.service_type != null &&
        this.formInput.service_type != ""
      ) {
        params.service_type = this.formInput.service_type;
      }
      if (this.formInput.from != null && this.formInput.from != "") {
        params.from = this.formInput.from;
      }
      if (this.formInput.to != null && this.formInput.to != "") {
        params.to = this.formInput.to;
      }
      if (this.formInput.from > this.formInput.to) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return;
      }
      this.$bus.$emit("show-loading", true);
      CmsRepository.listAllAutoPayment(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.items = response.data.data.data;
            this.paginate.total = response.data.data.last_page;
            this.notifyAlert("success", "Lấy danh sách thành công");
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
  },
  watch: {
    currentPage() {
      this.listAll();
    },
  },
  created() {
    this.listAll();
  },
};
</script>
